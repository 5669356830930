import React, { useEffect, useRef } from 'react';
import { useFrame } from '@react-three/fiber'; // Corrected import
import { useGLTF, useAnimations, Bounds } from '@react-three/drei';

export function Model(props) {
  const group = useRef(); // Create a ref for the group
  const { nodes, materials, animations } = useGLTF('/banana.glb')
  const { actions } = useAnimations(animations, group)

  useEffect(() => {
    
    // Play all animations (if you know specific names, you can play those specifically)
    Object.values(actions).forEach(action => action.play());

    // If you want to handle stopping, looping, or other properties, you can configure that here
    return () => {
      Object.values(actions).forEach(action => action.stop());
    };
  }, [actions]); // Re-run if actions change

  // Update loop for rotation
  useFrame(() => {
    if (group.current) {
        //group.current.rotation.y += 0.01; // Adjust rotation speed here
    }
  });

    // Example offset values - you'll need to determine the appropriate values for your model
    const offsetX = 0;  // Adjust these based on your model's alignment needs
    const offsetY = 0.55;
    const offsetZ = 0;

  return (

    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group position={[-offsetX, -offsetY, -offsetZ]}>
          <skinnedMesh
            name="banana"
            geometry={nodes.banana.geometry}
            material={materials.Banana}
            skeleton={nodes.banana.skeleton}
          />
          <group name="body">
            <skinnedMesh
              name="body_1"
              geometry={nodes.body_1.geometry}
              material={materials['metal parts']}
              skeleton={nodes.body_1.skeleton}
            />
            <skinnedMesh
              name="body_2"
              geometry={nodes.body_2.geometry}
              material= {materials.display}
              skeleton={nodes.body_2.skeleton}
            />
            <skinnedMesh
              name="body_3"
              geometry={nodes.body_3.geometry}
              material={materials['eye and mouth']}
              skeleton={nodes.body_3.skeleton}
            />
          </group>
          <primitive object={nodes.mixamorigHips} />
        </group>
      </group>
    </group>
  );
}

useGLTF.preload('/banana.glb');
